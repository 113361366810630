<template>
    <section class="rescue-section">
    <h2>Need to Surrender a Pet?</h2>
    <p>
        We understand that sometimes circumstances can change, and you may need help finding a safe, caring home for your reptile. If you have 
        a pet that requires rescue or rehoming, we're here to assist. Please click the link below to submit information about your pet, 
        and we'll get in touch to discuss the next steps. Unfortunately, we are currently unable to accept venomous or aquatic rescues.
    </p>
    <button @click="navigateToSurrender" aria-label="Submit a rescue request for your pet.">Submit a Rescue Request</button>
    </section>
</template>

<script>
export default {
    name: "rescueLink",
    methods: {
        navigateToSurrender() {
            this.$router.push({ name: 'Surrenders' })
        }
    }
}
</script>

<style>
.rescue-section {
    margin: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.rescue-section h2 {
    font-size: 2rem;
}

.rescue-section p {
    font-size: 1.5rem;
    color: #333;
    max-width: 800px;
    line-height: 1.6;
    margin: 1rem 0;
}

.rescue-section button {
    color: white;
    background-color: #056d16;
    padding: 10px 15px;
    text-decoration: none;
    font-size: 1.2rem;
    border-radius: 5px;
    border: none;
    transition: background-color 0.3s ease;
    margin-top: 2vh;
}

.rescue-section button:hover {
    background-color: #03a50b;
}

@media (max-width: 768px) {
    .rescue-section h2 {
        font-size: 1.8rem;
    }

    .rescue-section p {
        font-size: 1.2rem;
        max-width: 90%;
        line-height: 1.5;
    }

    .rescue-section button {
        font-size: 1rem;
    }
}
</style>