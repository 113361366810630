<template>
    <div class="adoption-form-modal">
        <div class="adoption-form">
            <h3>Adoption Inquiry Form</h3>
            <form @submit.prevent="handleSubmit">
                <!-- Name -->
                <label for="name">Full Name:</label>
                <input type="text" id="name" v-model="formData.name" placeholder="Enter your name here" required />

                <!-- Phone -->
                <label for="phone">Phone Number:</label>
                <input type="tel" id="phone" v-model="formData.phone" @input="formatPhoneNumber" placeholder="(111)-222-3333" required />
                <p v-if="errors.phone" class="error">{{ errors.phone }}</p>

                <!-- Email -->
                <label for="email">Email:</label>
                <input type="email" id="email" v-model="formData.email" placeholder="example@email.com" required />
                <p v-if="errors.email" class="error">{{ errors.email }}</p>

                <!-- Is this user's first reptile? -->
                <label>Is this your first reptile?</label>
                <div>
                    <label>
                        <input type="radio" value="yes" v-model="formData.firstReptile" /> Yes
                    </label>
                    <label>
                        <input type="radio" value="no" v-model="formData.firstReptile" /> No
                    </label>
                </div>

                <!-- Animal customer is looking for -->
                <label for="animalType">Which animal or type are you looking for?</label>
                <input type="text" id="animalType" v-model="formData.animalType" required />

                <!-- Agree not to breed -->
                <label>
                    <input type="checkbox" v-model="formData.agreeNotToBreed" required />
                    I understand that by adopting an animal from Infectious Herps LLC, I commit to providing a safe and loving home. I will not breed, sell, or use any adopted animals for commercial purposes or in a manner that may harm their well-being.
                </label>

                <!-- Submit button -->
                <div class="button-group">
                    <button type="submit">Submit Form</button>
                    <button type="button" @click="clearForm">Clear Form</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';

export default {
    name: 'AdoptionForm',
    props: {
        adoptee: {
            type: Object,
            required: false
        }
    },
    setup(props, { emit }) {
        const formData = ref({
            name: '',
            phone: '',
            email: '',
            firstReptile: '',
            agreeNotToBreed: 'false',
            animalType: ''
        });

        const errors = ref({});

        const formatPhoneNumber = () => {
            let cleaned = formData.value.phone.replace(/\D/g, '');

            if (cleaned.length <= 3) {
                formData.value.phone = `(${cleaned}`;
            } else if (cleaned.length <= 6) {
                formData.value.phone = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
            } else {
                formData.value.phone = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
            }
        };

        const handleSubmit = async () => {
            formatPhoneNumber();
            errors.value = validateForm(formData.value);
            
            if (Object.keys(errors.value).length === 0) {
                try {
                    const response = await axios.post('https://infectiousherps.com/api/adoption_submit.php', formData.value);

                    if (response.status === 200) {
                        console.log('Form submitted successfully:', response.data);
                        emit('formSubmitted', response.data);
                        clearForm();
                    } else {
                        console.error('Error submitting form:', response);
                    }
                } catch (error) {
                    console.error('Error submitting form:', error);
                }
            }
        };

        const validateForm = (data) => {
            const errors = {};

            // validate phone number
            if (!data.phone) {
                errors.phone = 'Phone number is required.';
            } else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(data.phone)) {
                errors.phone = 'Phone number must be in the format (111)-111-1111.';
            } 

            // validate email
            if (!data.email) {
                errors.email = 'Email is required.';
            } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
                errors.email = 'Invalid email format. (example@email.com)';
            }

            return errors;
        };

        const clearForm = () => {
            formData.value = {
                name: '',
                phone: '',
                email: '',
                firstReptile: '',
                agreeNotToBreed: 'false',
                animalType: ''
            }
        }

        return {
            formData,
            handleSubmit,
            clearForm,
            formatPhoneNumber,
            errors,
        };
    }
};
</script>

<style>
.adoption-form-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 700px;
    height: 90%;
    background-color: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    padding: 20px;
    z-index: 1000;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 8px;
    box-sizing: border-box;
}

.adoption-form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    margin-top: 2vh;
    width: 90%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
}

.adoption-form label {
    font-weight: bold;
    display: block;
    margin-top: 10px;
    width: 100%;
}

.adoption-form input[type="text"],
.adoption-form input[type="tel"],
.adoption-form input[type="email"] {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.adoption-form button {
    margin-top: 15px;
    padding: 10px 15px;
    background-color: #056d16;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-self: center;
}

.adoption-form button:hover {
    background-color: #03a50b;
}

.button-group {
    display: flex;
    gap: 10px;
    margin-top: 15px;
    justify-content: center;
}

.adoption-form button {
    padding: 10px 15px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
}

.adoption-form button[type="submit"] {
    background-color: #056d16;
}

.adoption-form button[type="submit"]:hover {
    background-color: #03a50b;
}

.adoption-form button[type="button"] {
    background-color: #888;
}

.adoption-form button[type="button"]:hover {
    background-color: #666;
}
</style>