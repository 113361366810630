<template>
  <nav class="navbar" :class="{ hidden: isHidden }">
    <div class="nav-logo">
      <router-link to="/" @click="scrollToTop">
        <img :src="logoSource" alt="Logo" />
      </router-link>
    </div>
    <ul class="nav-links" :class="{ 'nav-links-mobile': isMobileMenuOpen }">
      <li><router-link to="/" class="nav-link" @click="handleNavigation">Home</router-link></li>
      <li><router-link to="/about" class="nav-link" @click="handleNavigation">About</router-link></li>
      <li><router-link to="/ambassadors" class="nav-link" @click="handleNavigation">Ambassadors</router-link></li>
      <li><router-link to="/adoptions" class="nav-link" @click="handleNavigation">Adoptions</router-link></li>
      <li><router-link to="/caresheets" class="nav-link" @click="handleNavigation">CareSheets</router-link></li>
      <li><router-link to="/surrenders" class="nav-link" @click="handleNavigation">Surrenders</router-link></li>
    </ul>
    <div class="hamburger" @click="toggleMobileMenu" aria-label="Toggle menu">
      <i :class="isMobileMenuOpen ? 'fas fa-times' : 'fas fa-bars'"></i>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'AppNavbar',
  data() {
    return {
      lastScrollPosition: 0,
      isHidden: false,
      isMobileMenuOpen: false,
    };
  },
  computed: {
    logoSource() {
      return require(`@/assets/logo_white_background.jpg`);
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnMount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const currentScrollPosition = window.scrollY;

      // If the user scrolls down, hide the navbar
      if (currentScrollPosition > this.lastScrollPosition && currentScrollPosition > 100) {
        this.isHidden = true;
      }
      // If the user scrolls up, show the navbar
      else if (currentScrollPosition < this.lastScrollPosition) {
        this.isHidden = false;
      }

      // Update last scroll position
      this.lastScrollPosition = currentScrollPosition;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    handleNavigation() {
      if (this.isMobileMenuOpen) {
        this.isMobileMenuOpen = false;
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  transition: transform 0.3s ease;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.navbar.hidden {
  transform: translateY(-100%);
}

.nav-logo img {
  width: 80px;
  height: auto;
  border-radius: 5px;
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin: 0 15px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #056d16;
}

.hamburger {
  display: none;
  cursor: pointer;
  font-size: 1.8rem;
  color: white;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .nav-links-mobile {
    display: block;
    position: absolute;
    top: 8vh;
    right: 0;
    background-color: #000;
    width: 100%;
    padding: 10px;
    list-style: none;
    text-align: center;
  }

  .nav-links-mobile li {
    margin: 10px 0;
  }

  .hamburger {
    display: block;
  }
}
</style>
