import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './pages/HomePage.vue'; 
import AboutPage from './pages/AboutPage.vue';
import AmbassadorPage from './pages/AmbassadorPage.vue';
import AdoptionsPage from './pages/AdoptionsPage.vue';
import SurrenderForm from './pages/SurrenderFormPage.vue';
import PrivacyPolicy from './pages/PrivacyPolicy.vue';
import ComingSoonPage from './pages/ComingSoonPage.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage, 
    },
    {
        path: '/about',
        name: 'About',
        component: AboutPage,
    },
    {
        path: '/ambassadors',
        name: 'Ambassadors',
        component: AmbassadorPage,
    },
    {
        path: '/adoptions',
        name: 'Adoptions',
        component: AdoptionsPage,
    },
    {
        path: '/caresheets',
        name: 'CareSheets',
        component: ComingSoonPage,
    },
    {
        path: '/surrenders',
        name: 'Surrenders',
        component: SurrenderForm,
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: PrivacyPolicy,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

export default router;
