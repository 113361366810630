<template>
    <div class="coming-soon" ref="comingSoon" role="region" aria-label="Coming soon. This page under construction.">
      <div class="content">
        <h1>🚧 Coming Soon!</h1>
        <p>We're working hard to get this page up and running. Stay tuned!</p>
        <p>If you have any questions, feel free to reach out.</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ComingSoon'
  }
  </script>
  
  <style scoped>
  .coming-soon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background-color: #f7f7f7;
    text-align: center;
    padding: 0 20px;
  }
  
  .content {
    max-width: 600px;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .content h1 {
    font-size: 2.5rem;
    color: #333;
  }
  
  .content p {
    font-size: 1.1rem;
    color: #666;
  }

  @media (max-width: 768) {
    .content h1 {
      font-size: 2rem;
    }
    .content p {
      font-size: 1rem;
    }
  }
  </style>
  