<!-- src/components/About.vue -->
<template>
    <div class="about-page" ref="aboutPage" role="region" aria-label="About page">
      <h1 class="headerh1">Welcome to Infectious Herps!</h1>
      <p>
        We're not just any rescue—our heart lies in the care, rescue, and rehabilitation of some of nature's most misunderstood creatures: snakes and lizards. As a small, dedicated team of reptile enthusiasts, our mission is simple yet profound: to give these amazing animals a second chance at life.
      </p>
      <h2>Who We Are</h2>
      <p>
        Born from a deep passion for reptiles, Infectious Herps was founded with the belief that every creature deserves respect, care, and a loving home. What started as a hobby quickly grew into a full-fledged rescue operation, and today we're proud to be one of the few organizations dedicated to the rescue and rehoming of reptiles in the region. 
      </p>
      <p>
        Our team is made up of reptile experts and passionate animal lovers who understand the unique needs of these animals. We've seen firsthand the challenges they face—whether abandonded by overwhelmed owners, neglected, or mistreated. Our job is to step in and provide a safe haven where they can recover, regain trust, and ultimately find their perfect forever home. 
      </p>
      <h2>What We Do</h2>
      <ul>
        <li>Rescue: We take in reptiles from a variety of situations, including neglectful environments, abuse cases, and surrendered pets from owners unable to provide the care they need.</li>
        <li>Rehabilitate: Our first priority is to ensure each reptile receives the medical attention, nutrition, and behavioral support they need to thrive again.</li>
        <li>Rehome: Finding the right home is about more than just adoption—it's about education. We work closely with adopters to make sure they are prepared for the unique care reptiles require.</li>
      </ul>
      <h2>Why Reptiles?</h2>
      <p>
        Snakes and lizards are often misunderstood and sometimes feared, but these incredible creatures are fascinating, diverse, and rewarding companions when cared for properly. By focusing on reptiles, we aim to educate the public on their care, help dispel myths, and ensure that they are treated with the respect and compassion they deserve.
      </p>
      <h2>How You Can Help</h2>
      <p>
        Adopt: If you're ready to welcome a new scaly friend into your home, check out our Adoption Spotlight to meet our current rescues looking for a loving home.
      </p>
      <p>
        Volunteer: We're always looking for passionate individuals to help with everything from caring for reptiles to assisting with community outreach.
      </p>
      <p>
        Donate: Your generosity helps us continue providing medical care, proper housing, and rehabilitation to our reptiles in need. Every donation, big or small, makes a difference. 
      </p>
      <h2>Join Our Community</h2>
      <p>
        At Infectious Herps, we're more than just a rescue—we're a community of reptile lovers. Whether you're adopting, donating, or simply spreading the word, you're helping to make the world a better place for these unique animals.
      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutPage',
  };
  </script>
  
  <style scoped>
  .about-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .headerh1 {
    margin-top: 10vh;
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .about-page h2 {
    font-size: 1.8rem;
    margin-top: 30px;
  }
  
  .about-page p {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  
  .about-page ul {
    margin: 20px 0;
    padding-left: 20px;
  }
  
  .about-page li {
    font-size: 1.2rem;
    margin: 10px 0;
  }

  @media (max-width: 768px) {
    .about-page {
      margin-top: 5vh;
    }
  }
  </style>
  