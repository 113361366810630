<template>
  <div class="privacy-policy" ref="privacyPolicyPage" role="region" aria-label="Infectious Herps Privacy Policy">
    <h1>Privacy Policy</h1>
    <h2>Effective Date: 10/23/2024</h2>
    <h2>Introduction</h2>
    <p>
      At Infectious Herps, we value your privacy. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when
      you fill out our custom-built forms regarding contact details and animal adoption or surrender requests.
    </p>
    <h2>Information We Collect</h2>
    <p>We collect the following types of information through our custom forms:</p>
    <ul>
      <li>Contact Information: This includes your name, phone number, and email address. We use this information to contact you regarding inquiries or requests.</li>
      <li>Animal Information: If you are looking to adopt or surrender an animal, we may collect information about the type of animal, age, breed, and any specific needs or circumstances related to the animal.</li>
    </ul>
    <h2>How We Use Your Information</h2>
    <p>We use the information we collect for the following purposes:</p>
    <ul>
      <li>To Communicate: We may use your contact information to respond to your inquiries, provide updates, and share information regarding your requests.</li>
      <li>To Facilitate Adoption or Surrender: Your information will help us match you with suitable animals and manage the adoption or surrender process effectively.</li>
      <li>To Improve Our Services: We may analyze the information collected to enhance our programs and services.</li>
    </ul>
    <h2>Data Storage</h2>
    <p>Your personal information is securely stored in our website's database. We take the necessary steps to ensure that your data is protected and only accessible by authorized personnel.</p>
    <h2>Data Sharing and Disclosure</h2>
    <p>We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except as required by law or as necessary to protect our rights or the safety of others.</p>
    <h2>Data Security</h2>
    <p>We implement a variety of security measures to maintain the safety of your personal information. However, please remember that no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to protect your personal information, we cannot guarantee its absolute security.</p>
    <h2>Your Rights</h2>
    <p>You have the right to request access to the personal information we hold about you and to request that we correct or delete that information. To exercise these rights, please contact us using the information provided below.</p>
    <h2>Changes to This Privacy Policy</h2>
    <p>We may update this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account or by placing a prominent notice on our site.</p>
    <h2>Contact Us</h2>
    <p>If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>
    <p>Infectious Herps LLC.</p>
    <p>infectiousherps@gmail.com</p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
};
</script>

<style>
.privacy-policy {
max-width: 800px; /* Limit the width on larger screens */
margin: 0 auto;
margin-top: 5vh;
padding: 20px; /* Add padding around the content */
line-height: 1.6; /* Increase line height for readability */
}

.privacy-policy h1 {
font-size: 2.6rem;
}

.privacy-policy h2 {
font-size: 1.8em; /* Font size for headings */
margin-top: 20px; /* Spacing above headings */
margin-bottom: 10px; /* Spacing below headings */
}

.privacy-policy p {
font-size: 1em; /* Font size for paragraphs */
margin: 10px 0; /* Spacing for paragraphs */
}

.privacy-policy ul {
padding-left: 20px; /* Indent the list */
margin: 10px 0; /* Add spacing around the list */
}

.privacy-policy li {
margin-bottom: 5px; /* Spacing between list items */
}

/* Mobile Styles */
@media (max-width: 768px) {
.privacy-policy {
  padding: 15px; /* Less padding on smaller screens */
  margin-top: 10vh;
}

.privacy-policy h2 {
  font-size: 1.5em; /* Smaller font size for headings */
}

.privacy-policy p {
  font-size: 0.9em; /* Smaller font size for paragraphs */
}
}
</style>
