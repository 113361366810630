<template>
    <div class="lightbox-overlay" @click.self="close">
      <div class="lightbox-content">
        <img :src="images[currentIndex]" alt="Lightbox Image" class="lightbox-image" />
        <div class="lightbox-navigation">
          <button @click="prevImage">Previous</button>
          <button @click="nextImage">Next</button>
        </div>
        <button class="close-button" @click="close">Close</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LightBox',
    props: {
      images: {
        type: Array,
        required: true,
      },
      currentIndex: {
        type: Number,
        required: true,
      },
    },
    methods: {
      close() {
        this.$emit('close');
      },
      prevImage() {
        if (this.currentIndex > 0) {
          this.$emit('update:currentIndex', this.currentIndex - 1);
        } else {
          this.$emit('update:currentIndex', this.images.length - 1);
        }
      },
      nextImage() {
        if (this.currentIndex < this.images.length - 1) {
          this.$emit('update:currentIndex', this.currentIndex + 1);
        } else {
          this.$emit('update:currentIndex', 0);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .lightbox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .lightbox-content {
    position: relative;
    text-align: center;
  }
  
  .lightbox-image {
    max-width: 90%;
    max-height: 90vh;
    object-fit: contain;
    border-radius: 10px;
  }
  
  .lightbox-navigation {
    margin-top: 10px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
  }
  </style>
  