<template>
    <div class="surrender-form">
        <h2>Infectious Herps Surrender Intake Form</h2>
        <form @submit.prevent="handleSubmit">
            <!-- Name -->
            <div class="form-group">
                <label for="name">Full Name:</label>
                <input type="text" id="name" v-model="formData.name" placeholder="Enter your name here" required />
            </div>

            <!-- Phone -->
            <div class="form-group">
                <label for="phone">Phone Number:</label>
                <input type="tel" id="phone" v-model="formData.phone" @input="formatPhoneNumber" placeholder="(123)-456-7890" required maxlength="14"/>
                <p v-if="errors.phone" class="error">{{ errors.phone }}</p>
            </div>

            <!-- Email -->
            <div class="form-group">
                <label for="email">Email:</label>
                <input type="email" id="email" v-model="formData.email" placeholder="example@website.com" required />
                <p v-if="errors.email" class="error">{{  errors.email }}</p>
            </div>

            <!-- Intake Name -->
            <div class="form-group">
                <label for="intakeName">Intake Name:</label>
                <input type="text" id="intakeName" v-model="formData.intakeName" required />
            </div>

            <!-- Intake Age -->
            <div class="form-group">
                <label for="intakeAge">Intake Age:</label>
                <input type="text" id="intakeAge" v-model="formData.intakeAge" />
            </div>

            <!-- Intake Species -->
            <div class="form-group">
                <label for="intakeSpecies">Intake Species / Morph:</label>
                <input type="text" id="intakeSpecies" v-model="formData.intakeSpecies" />
            </div>

            <!-- Intake Condition -->
            <div class="form-group">
                <label for="intakeCondition">Intake Condition:</label>
                <input type="text" id="intakeCondition" v-model="formData.intakeCondition" placeholder="Fair, poor, healthy..." />
            </div>

            <!-- Intake Husbandry -->
            <div class="form-group">
                <label for="intakeHusbandry">Intake Husbandry (if known):</label>
                <input type="text" id="intakeHusbandry" v-model="formData.intakeHusbandry" />
            </div>

            <!-- Length of Owndership -->
            <div class="form-group">
                <label for="lengthOfOwnership">Length of Ownership:</label>
                <input type="text" id="lengthOfOwnership" v-model="formData.lengthOfOwnership" />
            </div>

            <!-- Submit button -->
             <div class="button-group">
                <button type="submit">Submit Form</button>
                <button type="button" @click="clearForm">Clear Form</button>
            </div>
        </form>
        <p>*Please note we are currently unable to accept venomous or aquatic rescues.</p>
    </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';

export default { 
    name: 'SurrenderForm',
    setup() {
        const formData = ref({
            name: '',
            phone: '',
            email: '',
            intakeName: '',
            intakeAge: '',
            intakeSpecies: '',
            intakeCondition: '',
            intakeHusbandry: '',
            lengthOfOwnership: ''
        });

        const errors = ref({});

        const formatPhoneNumber = () => {
            let cleaned = formData.value.phone.replace(/\D/g, '');

            if (cleaned.length <= 3) {
                formData.value.phone = `(${cleaned}`;
            } else if (cleaned.length <= 6) {
                formData.value.phone = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
            } else {
                formData.value.phone = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
            }
        };

        const handleSubmit = async () => {
            errors.value = validateForm(formData.value);

            if (Object.keys(errors.value).length === 0) {
                try {
                    // Using axios for the POST request
                    console.log('Axios:', axios); //check axios working
                    const response = await axios.post('https://infectiousherps.com/api/surrender_submit.php', formData.value);

                    if (response.status === 200) {
                        console.log('Form submitted successfully:', response.data);
                        clearForm();
                    } else {
                        console.error('Error submitting form:', response);
                    }
                } catch (error) {
                    console.error('Error submitting form:', error);
                }
            }
        };

        const validateForm = (data) => {
            const errors = {};

            // validate phone number
            if (!data.phone) {
                errors.phone = 'Phone number is required.';
            } else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(data.phone)) {
                errors.phone = 'Phone number must be in the format (111)-111-1111.';
            } 

            // validate email
            if (!data.email) {
                errors.email = 'Email is required.';
            } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
                errors.email = 'Invalid email format. (example@email.com)';
            }

            return errors;
        };

        const clearForm = () => {
            formData.value = {
                name: '',
                phone: '',
                email: '',
                intakeName: '',
                intakeAge: '',
                intakeSpecies: '',
                intakeCondition: '',
                intakeHusbandry: '',
                lengthOfOwnership: ''
            };
        };

        return {
            formData,
            handleSubmit,
            clearForm,
            errors,
            formatPhoneNumber,
        };
    }
};
</script>

<style>
.surrender-form {
    background-color: white;
    padding: 20px;
    margin-top: 5vh;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.surrender-form h2 {
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.form-group {
    width: 100%;
    margin-bottom: 15px;
}

.surrender-form label {
    font-weight: bold;
    display: block;
    margin-top: 10px;
}

.surrender-form input[type="text"],
.surrender-form input[type="tel"],
.surrender-form input[type="email"],
.surrender-form input[type="number"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.surrender-form input[type="text"]:focus,
.surrender-form input[type="tel"]:focus,
.surrender-form input[type="email"]:focus,
.surrender-form input[type="number"]:focus {
    border-color: #056d16;
    outline: none;
}

.button-group {
    display: flex;
    gap: 10px;
    margin-top: 15px;
}

.surrender-form button {
    padding: 10px 15px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
}

.surrender-form button[type="submit"] {
    background-color: #056d16;
}

.surrender-form button[type="submit"]:hover {
    background-color: #03a50b;
}

.surrender-form button[type="button"] {
    background-color: #888;
}

.surrender-form button[type="button"]:hover {
    background-color: #666;
}

.error {
    color: red;
    font-size: 0.9em;
    margin-top: 5px;
}

@media (max-width: 600px) {
    .surrender-form {
        padding: 15px;
        margin-top: 10vh;
        max-width: 100%;
        box-sizing: border-box;
    }

    .surrender-form h2 {
        font-size: 1.3em;
        margin-bottom: 15px;
    }

    .surrender-form input[type="text"],
    .surrender-form input[type="tel"],
    .surrender-form input[type="email"],
    .surrender-form input[type="number"] {
        padding: 8px;
        font-size: 13px;
    }

    .button-group {
        flex-direction: column;
        gap: 8px;
    }

    .surrender-form button {
        font-size: 13px;
        padding: 8px 12px;
    }

    .surrender-form p {
        text-align: center;
    }
}
</style>