<template>
    <div class="adoptions-page" @scroll="handleScroll" ref="adoptionsPage" role="region" aria-label="Adoption Spotlight">
        <h1>Adoption Spotlight</h1>
        <div class="adoptions-grid">
            <article class="adoption-card" v-for="adoptee in visibleAdoptions" :key="adoptee.id" aria-labelledby="adoptee.name">
                <img :src="adoptee.profilePicture" :alt="`${adoptee.name}'s profile picture`" class="profile-picture" @click="openLightbox(adoptee, 0)"/>
                <div class="additional-images">
                    <img v-for="(image, index) in adoptee.additionalImages" :key="index" :src="image"
                        :alt="`Additional image ${index + 1} of ${adoptee.name}`" class="thumbnail"
                        @click="openLightbox(adoptee, index + 1)" />
                </div>
                <h2 id="adoptee.name">{{ adoptee.name }}</h2>
                <p><strong>Age (approx.):</strong> {{ adoptee.age }}</p>
                <p><strong>Species:</strong> {{ adoptee.species }}</p>
                <p><strong>Sex/Morph:</strong> {{ adoptee.morph }}</p>
                <p><strong>Temperment:</strong> {{ adoptee.temperment }}</p>

                <button class="adoption-button" @click="openForm(adoptee)" aria-label="Contact Us">Contact Us!</button>

                <div v-if="adoptee.showForm" class="form-modal" role="dialog">
                    <button @click="closeForm(adoptee)" class="close-button" aria-label="Close the form">Close</button>
                    <!-- Replace GOOGLE FORM with custom form here -->
                    <!--<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfztK8WONA1E4Nb9IX97HjIT9-Way-P1Lgqoq2aFC6t7QHbsg/viewform?embedded=true" width="640" height="1432" frameborder="0" marginheight="0" marginwidth="0" title="Adoption Inquiry Form">Loading…</iframe> -->
                    <AdoptionForm :adoptee="adoptee" @submit="handleFormSubmit" />
                </div>
            </article>
        </div>
        <lightbox-comp v-if="lightboxImages.length" :images="lightboxImages" v-model:currentIndex="currentImageIndex"
            @close="closeLightbox" />
        <div v-if="loading" class="loading-spinner" role="alert">Loading more...</div>
        <div class="notice-section">
            <p>*Notice: Sex of the animal is not guaranteed. While we strive to accurately determine the sex of each animal, factors such as age, weight, or physical conditions (often caused by neglect) may affect our ability to do so with 100% accuracy.</p>
            <p>**Notice: A reptile’s temperament is closely tied to trust. Even with the most docile rescues, adopters must continue to build and maintain this trust through consistent handling and care.</p>
        </div>
    </div>
</template>

<script>
import LightboxComp from '@/components/LightboxComp.vue';
import { adoptionData } from '@/adoptionData';
import AdoptionForm from '@/components/adoptionForm.vue';

export default {
    name: 'AdopteeInfo',
    components: {
        LightboxComp,
        AdoptionForm,
    },
    data() {
        return {
            adoptions: adoptionData,
            visibleAdoptions: [],
            currentImageIndex: 0,
            lightboxImages: [],
            loading: false,
            isMobile: false,
        };
    },
    mounted() {
        this.isMobile = window.innerWidth <= 768;

        if (this.isMobile) {
            this.loadMoreAdoptions();
            window.addEventListener('scroll', this.handleScroll);
        } else {
            this.visibleAdoptions = this.adoptions;
        }

        this.adoptions.forEach(adoptee => {
            adoptee.showForm = false;
        });
    },
    methods: {
        openLightbox(adoptee, index) {
            const allImages = [adoptee.profilePicture, ...(Array.isArray(adoptee.additionalImages) ? adoptee.additionalImages : [])];
            this.lightboxImages = allImages;
            this.currentImageIndex = index;
        },
        closeLightbox() {
            this.lightboxImages = [];
        },
        calculateVisibleEntries() {
            const containerWidth = this.$refs.adoptionsPage.clientWidth;
            const cardWidth = 220; 
            const visibleCount = Math.floor(containerWidth / cardWidth);
            return visibleCount > 0 ? visibleCount + 1 : 1; // Load one more than fits
        },
        loadMoreAdoptions() {
            const itemsToLoad = this.calculateVisibleEntries();
            const newAdoptions = this.adoptions.slice(this.visibleAdoptions.length, this.visibleAdoptions.length + itemsToLoad);

            if (newAdoptions.length) {
                this.visibleAdoptions.push(...newAdoptions);
            }

            // Set loading to false as necessary
            this.loading = false;
        },
        handleScroll() {
            if (!this.isMobile) return; // do not trigger lazy loading on non-mobile

            const container = this.$refs.adoptionsPage;
            if (
                container.scrollTop + container.clientHeight >= container.scrollHeight - 100 &&
                !this.loading &&
                this.visibleAdoptions.length < this.adoptions.length
            ) {
                this.loadMoreAdoptions();
            }
        },
        openForm(adoptee) {
            adoptee.showForm = true;
        },
        closeForm(adoptee) {
            adoptee.showForm = false;
        },
        handleFormSubmit(formData) {
            // @TODO Handle FORM SUBMISSION here
            // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            console.log('Form submitted with Data:', formData);
        },
    },
    beforeUnmount() {
        if (this.isMobile) {
            window.removeEventListener('scroll', this.handleScroll);
        }
    },
};
</script>

<style scoped>
.adoptions-page {
    padding: 20px;
    margin-top: 10vh;
    overflow-y: auto;
}

.adoptions-page h1 {
    text-align: center;
    margin-bottom: 5vh;
    font-size: 3vh;
}

.adoptions-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); 
    gap: 20px;
    padding: 0 10px;
    justify-items: center;
}

.adoption-card {
    max-width: 480px;
    width: 100%;
    display: flex; 
    flex-direction: column; 
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    transition: box-shadow 0.3s;
    height: 100%;
}

.adoption-card h2 {
    font-size: 1.5rem;
    margin-top: 10px; 
    margin-bottom: 10px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.adoption-card p {
    text-align: left;
    margin-bottom: 0px;
}

.adoption-card:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.profile-picture {
    height: 50vh;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.additional-images {
    display: flex;
    height: 10vh;
    width: auto;
    justify-content: center;
    margin-top: 10px;
}

.thumbnail {
    height: 100%;
    width: 30%;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    object-fit: cover;
}

.adoption-button {
    font-size: 1.5rem;
    color: white;
    background-color: #056d16;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    margin-top: auto;
}

.form-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 700px;
    height: 90%;
    background-color: #03a50b;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    padding: 20px;
    z-index: 1000;
    overflow-y: auto;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    cursor: pointer;
}

.notice-section {
    text-align: center;
    margin: 20px auto;
    padding: 10px;
    max-width: 600px;
    line-height: 1.5;
    color: #333;
    font-size: 0.8rem;
}

.loading-spinner {
    text-align: center;
    margin: 20px;
    font-size: 18px;
}

@media (min-width: 1024px) {
    .adoptions-grid {
        grid-template-columns: repeat(autofill, minmax(250px, 1fr));
        max-width: 1200px;
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .adoptions-page h1 {
        font-size: 3rem;
    }
}

@media (max-width: 600px) {
    .adoptions-grid {
        grid-template-columns: 1fr; 
    }

    .form-modal {
        width: 95%;
        max-width: 100vh;
        height: 85%;
        max-width: none;
        padding: 15px;
    }

    .form-modal iframe {
        width: 450px;
    }

    .close-button {
        font-size: 16px;
    }
}

@media (max-width: 400px) {
    .form-modal {
        width: 100%;
        height: 85%;
    }

    .form-modal iframe {
        width: 300px;
    }

    .close-button {
        font-size: 14px;
    }
}
</style>
