<template>
    <div class="ambassadors-page" ref="ambassadorsPage" role="region" aria-label="Our Ambassadors">
      <h1>Meet Our Ambassadors</h1>
      <p class="intro-snippet">Ambassadors are our most sociable and friendly animals, who thrive in human company. 
         They enjoy being gently handled and interacting with people, making them the perfect 
         representatives for our cause. Each ambassador has a unique story to share, and together, 
         they help raise awareness about the importance of compassion and care for all living beings.
      </p>
      <div class="ambassadors-grid">
        <div class="ambassador-card" v-for="ambassador in ambassadors" :key="ambassador.id"> 
          <img :src="ambassador.profilePicture" :alt="ambassador.name" class="profile-picture" @click="openLightbox(ambassador, 0)"/>
          <div class="additional-images">
            <img 
              v-for="(image, imageIndex) in ambassador.additionalImages" 
              :key="imageIndex"
              :src="image" 
              :alt="`Additional image ${imageIndex + 1} of ${ambassador.name}`" 
              class="thumbnail" 
              @click="openLightbox(ambassador, imageIndex + 1)" 
            />
          </div>
          <h2>{{ ambassador.name }}</h2>
          <p><strong>Species:</strong> {{ ambassador.species }}</p>
          <p><strong>Time with Infectious Herps:</strong> {{  ambassador.timeWIH }}</p>
          <p><strong>Story:</strong> {{ ambassador.story }}</p>
        </div>
      </div>
      <lightbox-comp 
        v-if="lightboxImages.length" 
        :images="lightboxImages" 
        v-model:currentIndex="currentImageIndex" 
        @close="closeLightbox" 
      />
    </div>
  </template>
  
  <script>
  import LightboxComp from '@/components/LightboxComp.vue';
  
  export default {
    name: 'AmbassadorInfo',
    components: {
      LightboxComp,
    },
    data() {
      return {
        ambassadors: [
          {
            id: 1,
            name: 'Ragnar',
            species: 'Ball Python (Python Regius)',
            timeWIH: '4 years',
            story: 'The one who started it all! Ragnar was purchased as a personal pet from a local pet store. His docile nature rekindled our founder\'s love of reptiles.',
            profilePicture: require('@/assets/Ragnar1.jpg'),
            additionalImages: [
              require('@/assets/Ragnar2.jpg'),
              require('@/assets/Ragnar3.jpg'),
              require('@/assets/Ragnar4.jpg'),
            ],
          },
          {
            id: 2,
            name: 'Ostara',
            species: 'Boa Constrictor (Boa Constrictor)',
            timeWIH: '7 months',
            story: 'Ostara was surrendered to us because her previous owner was starting anew across country. She is on a diet due to her size, which came from eating all her picky snake brother\'s leftovers.',
            profilePicture: require('@/assets/Ostara1.jpg'),
            additionalImages: [
              require('@/assets/snakeSilhouette1.jpg'),
              require('@/assets/snakeSilhouette1.jpg'),
              require('@/assets/snakeSilhouette1.jpg'),
            ],
          },
          {
            id: 3,
            name: 'Roscoe',
            species: 'Blue Tegu (Salvator Marianae)',
            timeWIH: '2 months',
            story: 'A coworker (at our lame day job) approached us about a friend of his looking to rehome a lizard he no longer had time for. We were in absolute disbelief when we saw what kind of lizard. Of course we said yes!',
            profilePicture: require('@/assets/Roscoe1.jpg'),
            additionalImages: [
              require('@/assets/Roscoe2.jpg'),
              require('@/assets/Roscoe3.jpg'),
              require('@/assets/snakeSilhouette1.jpg'),
            ],
          },
          {
            id: 4,
            name: 'Lilly',
            species: 'Reticulated Python (Malayopython Reticulatus)',
            timeWIH: '3 years',
            story: 'Previous owner surrendered her due to her growing size. She has been through live feeding injuries, a fallen heat source catching her enclosure on fire and foil tape stuck to her face and neck. She is still a sweetheart!',
            profilePicture: require('@/assets/Lilly1.jpg'),
            additionalImages: [
              require('@/assets/Lilly2.jpg'),
              require('@/assets/snakeSilhouette1.jpg'),
              require('@/assets/snakeSilhouette1.jpg'),
            ],
          },
        ],
        lightboxImages: [],
        currentImageIndex: 0,
      };
    },
    methods: {
      openLightbox(ambassador, imageIndex) {
        const allImages = [ambassador.profilePicture, ...(Array.isArray(ambassador.additionalImages) ? ambassador.additionalImages : [])];

        this.lightboxImages = allImages;
        this.currentImageIndex = imageIndex;
      },
      closeLightbox() {
        this.lightboxImages = [];
      },
    },
  };
  </script>
  
  <style scoped>
  .ambassadors-page {
    padding: 20px;
    margin-top: 10vh;
  }
  
  .ambassadors-page h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 3vh;
    white-space: normal;
    overflow: visible;
  }

  .intro-snippet {
    width: 100%;
    max-width: 1600px;
    text-align: center;
    margin: 0 auto;
    padding: 15px;
    font-size: 1.5rem;
    line-height: 1.6;
  }
  
  .ambassadors-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .ambassador-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    transition: box-shadow 0.3s;
  }
  
  .ambassador-card:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }
  
  .profile-picture {
    width: 100%;
    border-radius: 10px;
  }
  
  .additional-images {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .thumbnail {
    width: 30%;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .ambassadors-page {
      max-width: 90%;
      margin-top: 10vh;
    }

    .ambassadors-page h1 {
      font-size: 3rem;
    }
    .intro-snippet {
      font-size: 1.5rem;
      max-width: 90%;
      line-height: 1.5;
      padding: 10px;
    }
  }

  @media (max-width: 600px) {
    .ambassadors-grid {
        grid-template-columns: 1fr;
    }
  }
  </style>
  