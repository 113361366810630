<template>
  <div class="newest-rescues">
    <h2>Adoption Spotlight</h2>
    <div class="rescues-grid">
      <div v-for="rescue in rescues" :key="rescue.name" class="rescue-item">
        <img :src="rescue.image" :alt="`${rescue.name} - ${rescue.species}`" class="rescue-image"/>
        <div class="rescue-info">
          <p><strong>Name:</strong> {{ rescue.name }}</p>
          <p><strong>Hatch Date:</strong> {{ rescue.age }}</p>
          <p><strong>Species:</strong> {{ rescue.species }}</p>
          <p><strong>Morph:</strong> {{ rescue.morph }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: 'NewestRescues',
    data() {
      return {
        // array of recent rescues NAME / AGE / SPECIES / IMAGE FILE
        rescues: [
          { name: "Priscilla", age: 'approx. 3 years', species: 'Ball Python (Python Regius)', morph: 'Wild Type / F', image: require('@/assets/Priscilla1.jpg') },
          { name: "Valerie", age: 'Unknown - assumed young adult', species: 'Bearded Dragon (Pogona Vitticeps)', morph: 'Normal Yellow / Tan / F', image: require('@/assets/Valerie1.jpg') },
          { name: "Phoenix", age: 'Unknown - assumed older adult', species: 'Bearded Dragon (Pogona Vitticeps)', morph: 'Wild Type / Dark Grey / M', image: require('@/assets/Pheonix1.jpg') },
        ],
      };
    },
  };
  </script>
  
<style scoped>
  .newest-rescues {
  text-align: center;
  margin: 0 0;
}

.newest-rescues h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.3);
  background: linear-gradient(90deg, #03a50b 0%, #056d16 100%);
}

.rescues-grid {
  display: flex;
  margin: 5vh;
  flex-direction: column; /* Stack rescue items vertically */
  align-items: center;
  gap: 30px;
}

.rescue-item {
  display: flex;
  flex-direction: row; /* Display image and info side-by-side */
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Set a max width for better formatting */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.rescue-image {
  width: 50%;
  height: auto;
  border-radius: 10px;
  margin-right: 50px; /* Adds spacing between image and info */
}

.rescue-info {
  width: 50%;
  text-align: right; 
  overflow-wrap: break-word;
}

.rescue-info p {
  font-size: 2.5rem;
  margin: 5px 0;
}

@media (max-width: 768px) {
  .rescue-item {
    flex-direction: column; 
    text-align: center;
  }

  .rescue-image {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%; 
  }

  .rescue-info {
    text-align: center; 
  }

  .rescue-info p {
    font-size: 1.5rem;
  }
}

@media(max-width: 480px) {
  .newest-rescues h2 {
    font-size: 1.8rem;
  }

  .rescue-item {
    padding: 15px;
  }

  .rescue-image {
    width: 90%;
  }

  .rescue-info {
    font-size: 1.2rem;
  }

  .rescue-info p {
    margin: 3px 0;
  }
}

@media(max-width: 360px) {
  .newest-rescues h2 {
    font-size: 1.5rem;
  }

  .rescues-grid {
    margin: 3vh;
  }

  .rescue-item {
    padding: 10px;
  }

  .rescue-image {
    width: 85%;
  }

  .rescue-info {
    font-size: 1rem;
  }

  .rescue-info p {
    margin: 2px 0;
  }
}
  </style>