<template>
    <footer class="footer">
      <div class="footer-content">
        <div class="donation">
            <a href="https://cash.app/$InfectiousRescue/" target="_blank" rel="noopener noreferrer">Support Our Cause!</a>
        </div>
        <nav class="social-links" aria-label="Social Media Links">
          <a href="https://www.instagram.com/infectious_herps/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <i class="fab fa-instagram"></i>
          </a>
          <a href="https://www.facebook.com/profile.php?id=61553174939465" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a href="mailto:infectiousherps@gmail.com" rel="noopener noreferrer" aria-label="Email Infectious Herps">
            <i class="fas fa-envelope"></i>
          </a>
        </nav>
        <p>
          <router-link to="/privacy" class="privacy-link">Privacy Policy</router-link>
        </p>
        <small>&copy; 2024 Copyright Infectious Herps LLC. All rights reserved.</small>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: "AppFooter",
  };
  </script>
  
  <style scoped>
  .footer {
    background-color: #000000;
    color: white;
    padding: 20px;
    text-align: center;
    position: relative;
    margin-top: auto;
    width: 100%;
    box-sizing: border-box;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .donation {
    margin-bottom: 10px;
  }

  .donation a {
    color: #056d16;
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s ease;
    position: center;
  }
  
  .social-links {
    margin-bottom: 10px;
  }
  
  .social-links a {
    color: white;
    margin: 0 10px;
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  
  .social-links a:hover {
    color: #056d16; 
  }
  
  .footer p {
    margin: 0;
    font-size: 0.9rem;
    color: white;
  }
  
  .privacy-link { 
    color: white;
    text-decoration: none;
  }

  @media (max-width: 768) {
    .footer {
      padding: 15px;
    }

    .footer-content {
      flex-direction: column;
      align-items: center;
    }

    .donation a {
      font-size: 1.1rem;
    }

    .social-links {
      margin-bottom: 15px;
    }

    .social-links a {
      font-size: 1.4rem;
      margin: 0 8px;
    }

    .footer p {
      font-size: 0.8rem;
    }
  }
  </style>
  