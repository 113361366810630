<template>
    <div class="home-container" ref="homePage" role="region" aria-label="Infectious Herps home page.">
      <Hero />
      <NewestRescues />
      <aravSection />
      <rescueLink />
      <PrintifySection />
    </div>
  </template>
  
  <script>
  import Hero from '@/components/hero.vue';
  import NewestRescues from '@/components/monthlyPics.vue';
  import aravSection from '@/components/aravLink.vue';
  import rescueLink from '@/components/rescueLink.vue';
  import PrintifySection from '@/components/printify.vue';
  
  export default {
    name: 'HomePage',
    components: {
      Hero,
      NewestRescues,
      aravSection,
      rescueLink,
      PrintifySection,
    },
  };
  </script>
  
  <style>
  .home-container {
    display: flex;
    flex-direction: column; 
  }
  </style>
  