<template>
  <div class="hero-section">
    <div class="text-container">
      <h1>Infectious Herps</h1>
      <h2 class="animate-text">Reptile Rescue in Central Pennsylvania</h2>
      <button class="cta-button" @click="navigateToAmbassadors" aria-label="Meet our ambassadors">Meet Our Family</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroSection',
  methods: {
    navigateToAmbassadors() {
      this.$router.push({ name: 'Ambassadors' });
    }
  }
};
</script>

<style scoped>
.hero-section {
  position: relative;
  height: 60vh;
  width: 100%;
  margin: 0;
  background-image: url('@/assets/heroImage.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.text-container {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.text-container h1 {
  font-size: 4rem;
  margin: 0;
  font-weight: bold;
  animation: fadeIn 1.5s ease-out forwards;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
}

.text-container h2 {
  font-size: 3rem;
  margin: 0;
  font-weight: bold;
  animation: fadeIn 1.5s ease-out forwards;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
}

.cta-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1.5rem;
  color: white;
  background-color: #056d16;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.cta-button:hover {
  background-color: #03a50b;
  transform: translateY(-5px);
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.4);
}

/* Gradient overlay */
.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

/* Keyframes for animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1200px) {
  .hero-section {
    background-size: cover;
  }
}

@media (max-width: 768px) {
  .text-container h2 {
    font-size: 2.5rem;
    padding: 0 15px;
  }

  .cta-button {
    font-size: 1.2rem;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .hero-section {
    height: 80vh;
  }

  .text-container h2 {
    font-size: 2rem;
  }
  .cta-button {
    font-size: 1rem;
    padding: 6px 12px;
  }
}
</style>
