export const adoptionData = [
    {
        id: 1,
        name: 'Priscilla',
        age: '3 years',
        species: 'Ball Python (Python Regius)',
        morph: 'Wild Type / F',
        temperment: 'Handleable',
        profilePicture: require('@/assets/Priscilla1.jpg'),
        additionalImages: [
            require('@/assets/Priscilla2.jpg'),
            require('@/assets/Priscilla3.jpg'),
            require('@/assets/Priscilla4.jpg'),
        ],
    },
    {
        id: 2,
        name: 'Valerie',
        age: 'Unknown - assumed young adult',
        species: 'Bearded Dragon (Pogona Vitticeps)',
        morph: 'Normal Yellow / Tan / F',
        temperment: 'Handleable',
        profilePicture: require('@/assets/Valerie1.jpg'),
        additionalImages: [
            require('@/assets/snakeSilhouette1.jpg'),
            require('@/assets/snakeSilhouette1.jpg'),
            require('@/assets/snakeSilhouette1.jpg'),
        ],
    },
    {
        id: 3,
        name: 'Phoenix',
        age: 'Unknown - assumed older adult',
        species: 'Bearded Dragon (Pogona Vitticeps)',
        morph: 'Wild Type / Dark Grey / M',
        temperment: 'Skittish',
        profilePicture: require('@/assets/Pheonix1.jpg'),
        additionalImages: [
            require('@/assets/Pheonix2.jpg'),
            require('@/assets/Pheonix3.jpg'),
            require('@/assets/Pheonix4.jpg'),
        ],
    },
];
