<!-- src/App.vue -->
<template>
  <div id="app">
    <AppNavbar />
    <router-view />
    <AppFooter />
  </div>
</template>

<script>

import AppNavbar from './components/navbar.vue';
import AppFooter from './components/footer.vue';
import '@fortawesome/fontawesome-free/css/all.css';

export default {
  name: 'App',
  components: {
    AppNavbar,
    AppFooter,
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
#app {
  font-family: Quicksand;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body, html {
  margin: 0; /* Ensures no default margin */
  padding: 0; /* Ensures no default padding */
  width: 100%; /* Ensures the body takes full width */
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

router-view {
  flex: 1;
}
</style>
