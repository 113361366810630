<template>
    <section class="find-a-vet-section">
      <div class="find-a-vet-content">
        <h2>Find a Reptile Veterinarian Near You</h2>
        <p>It's crucial to have a qualified veterinarian to ensure the health and wellbeing of your reptiles and amphibians. Use ARAV's "Find-A-Vet" tool to locate specialized care near you.</p>
        <button 
          class="vet-link-button"
          @click="openLink"
          aria-label="Find a veterinarian near you using ARAV's Find-A-Vet tool">   
          Find a Vet Now
        </button>
      </div>
      <div class="find-a-vet-image">
        <img src="@/assets/ARAV Logo with no background.png" alt="Logo of ARAV, the Association of Reptile and Amphibian Veterinarians" />
      </div>
    </section>
</template>

<script>
export default {
    name: 'aravSection',
    methods: {
      openLink() {
        window.open("https://members.arav.org/search/custom.asp?id=3661", "_blank", "noopener,noreferrer");
      },
    },
};
</script>

<style>
.find-a-vet-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: calc(100% - 10vh);
  margin: 5vh auto; 
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  overflow: hidden; 
}

.find-a-vet-content {
  max-width: 600px;
  text-align: left;
  padding-right: 20px; 
}

.find-a-vet-content h2 {
  font-size: 2rem;
  color: #056d16;
  margin-bottom: 10px;
}

.find-a-vet-content p {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
}

.vet-link-button {
  display: inline-block;
  background-color: #056d16;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
}

.vet-link-button:hover {
  background-color: #03a50b;
}

.find-a-vet-image img {
  width: 300px;
  height: auto;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .find-a-vet-section {
    flex-direction: column;
    text-align: center;
    padding: 20px;
    width: calc(100% - 10vh);
  }

  .find-a-vet-content {
    max-width: 100%; 
    padding-right: 0;
  }

  .find-a-vet-image img {
    width: 100%; 
    margin-top: 20px;
  }
}

</style>