<template>
    <section class="shop-section">
      <h2>Visit our Printify Shop!</h2>
      <div class="product-grid">
        <div class="product" v-for="product in products" :key="product.id">
          <a :href="product.link" target="_blank" rel="noopener noreferrer">
            <img :src="product.image" :alt="product.name" class="product-image" />
          </a>
          <div class="product-details">
            <p class="product-name">{{ product.name }}</p>
            <p class="product-price">{{ product.price }}</p>
            <a :href="product.link" target="_blank" rel="noopener noreferrer" class="shop-link">Check it out!</a>
          </div>
        </div>
      </div>
      <h2>...and plenty more where these came from</h2>
      <a class="printify-products" href="https://infectious-herps-llc.printify.me/products" target="_blank" rel="noopener noreferrer">Printify Shop</a>
    </section>
  </template>

<script>
export default {
  name: "PrintifySection",
  data() {
    return {
      products: [
        {
          id: 1,
          name: 'Infected Herps Zip-Up Sweatshirt',
          price: '$45.99',
          image: require('@/assets/hoodie_front.jpg'),
          link: 'https://infectious-herps-llc.printify.me/product/13630012/unisex-heavy-blend-full-zip-hooded-logo-sweatshirt',
        },
        {
          id: 2,
          name: 'Logo Spiral Notebook',
          price: '$14.99',
          image: require('@/assets/notebook_1.jpg'),
          link: 'https://infectious-herps-llc.printify.me/product/13630289/logo-spiral-notebook-ruled-line',
        },
      ],
    };
  },
};
</script>

<style scoped>
.shop-section {
  padding: 50px;
  text-align: center;
}

.product-grid {
  display: flex;
  flex-direction: column; /* Stack products vertically */
  align-items: center;
}

.product {
  display: flex;
  flex-direction: row; /* Display image and details side-by-side */
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Ensure product row takes full width */
  max-width: 800px; 
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc; 
  border-radius: 10px;
}

.product-image {
  width: 200px;
  height: auto;
}

.product-details {
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
  text-align: right; 
  flex-grow: 1;
}

.product-name {
    margin-bottom: 5px;
}

.product-price {
    color: #056d16;
    font-weight: bold;
    margin-bottom: 10px;
}

.shop-link {
  display: inline-block;
  background-color: #056d16;
  color: white;
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.shop-link:hover {
  background-color: #03a50b;
}

.printify-products {
    display: inline-block;
    background-color: #056d16;
    color: white;
    padding: 10px 15px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.printify-products:hover {
    background-color: #03a50b;
}

@media (max-width: 768px) {
  .product {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .product-image {
    width: 150px;
  }

  .product-details {
    align-items: center;
    text-align: center;
    padding-left: 0;
  }

  .shop-link {
    margin-top: 10px;
  }
}

@media(max-width: 480px) {
  .shop-section {
    padding: 20px;
  }

  .product-image {
    width: 120px;
  }

  .product-details {
    align-items: center;
    text-align: center;
  }

  .product-name {
    font-size: 0.9rem;
  }

  .product-price {
    font-size: 0.9rem;
  }

  .shop-link {
    font-size: 0.9rem;
    padding: 8px 12px;
  }
}
</style>